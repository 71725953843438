import React from 'react';

import { App, Helmet } from 'modules/app';
import { MainNavigation } from 'modules/navigation';

import { StemiLabPage } from 'modules/lab';

export default class LabPage extends React.Component {
  render() {
    return (
      <App>
        <Helmet route={StemiLabPage.route} title={StemiLabPage.title} />
        <MainNavigation />
        <StemiLabPage />
      </App>
    );
  }
}
